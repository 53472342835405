import React from "react";
import ProductCard from './productCard/ProductCard'
import '../css/search.css'
import '../css/index.css'

export default function renderSearchResults(props) {
    if (Object.keys(props.results).length && props.results.length) {
        return (
            <div className="results-container">
                {props.results.map(result => {
                    return (
                        <ProductCard key={result.ProductSku} result={result} />
                    )
                })}
            </div>
        )
    }else {
        return null
    }
}