import React from "react";
import { createRoot } from 'react-dom/client';
import "./css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { Auth0Provider } from "@auth0/auth0-react";
import { ClientSelectedProvider } from '../src/provider/ClientSelectedProvider'


Amplify.configure(config);

const container = document.getElementById('root');
const root = createRoot(container);

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

root.render(
	<ClientSelectedProvider>
	<Auth0Provider
		domain= {domain}
		clientId={clientId}
		authorizationParams={{
			redirect_uri: window.location.origin,
			audience: process.env.REACT_APP_AUTH0_AUDIENCE,
			scope: "api.stock.get api.stock.get.all api.client.create api.client.get api.client.update api.command.create",
		}}
	>
		<App />
	</Auth0Provider>
	</ClientSelectedProvider>
	);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
