import React from "react"

export default function SearchBar(props){
    return(
        <div>
            <input 
            className="search-bar"
            type="text" 
            name="query"
            value={props.query} 
            id="search=input" 
            placeholder="Rechercher par Gencod, Référence..."
            onChange={props.handleInputChange}/>
        </div>
        )
}