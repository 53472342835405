import React from "react";
import ArrivalCard from "./ArrivalCard";
import "../../css/App.css";

export default function ArrivalsModal(props) {
	const [showModal, setShowModal] = React.useState(false);
	return (
		<>
			<button
				className="arrival-button"
				onClick={() => setShowModal(true)}
			>
				<div className="info-arrivages-container">
					<i
						className="fa fa-info-circle info-icon"
						aria-hidden="true"
					></i>
          <div className="arrow-left"></div>
				</div>
			</button>
			{showModal ? (
				<>
					<div
						className="modal-card"
						onClick={() => setShowModal(false)}
					>
						<div className="modal-background">
							<div className="modal">
								<div className="modal-title-container">
									<h3 className="modal-title">
										Prochaines arrivages{" "}
										{props.result.ProductSku}
									</h3>
								</div>
								<div className="modal-content">
									{props.result.AvailibilityList.map(
										(item, index) => (
											<ArrivalCard
												key={item.ProductSku}
												item={item}
											/>
										)
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="modal-black-background"></div>
				</>
			) : null}
		</>
	);
}
