import React from "react";
import "../css/App.css";


export default function FooterSticky(props) {
    return(
        <div>
            <footer className="footer">
                <p>🚀 Date de mise à jour : <strong>{props.date}</strong></p>
            </footer>
        </div>
    );
}