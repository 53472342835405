import React from "react";
import { StockCard } from "./StockCard";
import { AvailabilityDate } from "./AvailabilityDateCard";
import { StockStatusTag } from "./StockStatusTag";
import ArrivalsModal from "../arrivalsModal/ArrivalsModal";
import "../../css/App.css";

export default function ProductCard(props) {
	return (
		<div className="product-stock-card">
			<div>
				<h2 className="search-card-title">{props.result.ProductSku}</h2>
			</div>
			<div className="subtitle-tag">
				<StockStatusTag result={props.result} />
			</div>
			<div className="big-card-bottom">
				<StockCard result={props.result} />
				<AvailabilityDate result={props.result} />
			</div>
			<div className="info-arrivages-container">
				{props.result.AvailibilityList.length !== 0 &&
					<ArrivalsModal result={props.result} />}
			</div>
		</div>
	);
}
