import React from 'react';

export const ArrivalStateTag = (props) => {
    function ArrivalStateTagDispo (){
        switch(props.result){
            case 0:
                return 'EN COMMANDE';
            case 1:
                return 'PRÊT EMBARQUER';
            case 2:
                return 'EN TRANSIT';
            case 3:
                return 'EN DOUANE';
            default:
                return "PAS D'INFO";
        }
    }
    return(
        <p className="stock-tag" style={{background: "#fff", color: "#000", padding: "3px 5px"}}>{ArrivalStateTagDispo()}</p>
    )
}