import React from "react"
import Scanner from "./Scanner"
import "../../css/search.css"

export default function ModalScanner(props) {
  const [showModalScanner, setShowModalScanner] = React.useState(false);
  const [scanning, setScanning] = React.useState(false);
  const scannerRef = React.useRef(null);
  return (
    <>
      <button
        className="float-button"
        onClick={() => {
          setShowModalScanner(true);
          setTimeout(() => {
            setScanning(true);
          }, 20);
        }}
      >
        <div>
          <i className="fa fa-camera camera-button" aria-hidden="true"></i>
        </div>
      </button>
      {showModalScanner ? (
        <>
          <div
            className="z-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
            onClick={() => {
              setShowModalScanner(false);
              setScanning(false);
            }}
          >
            <div>
              <div className="border-0 rounded-lg shadow-lg w-full h-auto pb-6 flex flex-col items-center justify-center bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    SCANNEZ VOTRE CODE <i className="fas fa-barcode"></i>
                  </h3>
                </div>
                <div ref={scannerRef} style={{ position: "relative" }}>
                  <canvas
                    className="drawingBuffer"
                    style={{
                      position: "absolute",
                      top: "0px",
                    }}
                    width="300"
                    height="300"
                  />
                  {scanning ? (
                    <Scanner
                      scannerRef={scannerRef}
                      onDetected={props.onScanBarcode}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  {props.scannerResult && (
                    <>
                      <p>{props.scannerResult}</p>
                      <button
                        type="button"
                        className="focus:outline-none text-white text-sm py-2.5 px-5 rounded-md bg-blue-500 hover:bg-blue-600 hover:shadow-lg"
                        onClick={() => {
                          setShowModalScanner(false);
                          setScanning(false);
                        }}
                      >
                        RECHERCHER
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
