import React from 'react';

import { ArrivalStateTag } from '../productCard/ArrivalStateTag'
import '../../css/App.css'


export default function ArrivalCard(props) {
  return (
    <>
      <div
        className="arrival-card" key={props.item.ProductSku}
      >
        <div>
          <div>
            <p className="title-arrivage">DATE</p>
          </div>
          <div>
            <p className="text-arrivage">
              {new Date(props.item.ArrivalDate).toLocaleDateString()}
            </p>
          </div>
        </div>
        <div>
          <div>
            <p className="title-arrivage">QTE</p>
          </div>
          <div>
            <p className="text-arrivage">{props.item.AvailableQty}</p>
          </div>
        </div>
        <div>
          <div>
            <p className="title-arrivage">ETAT</p>
          </div>
          <div>
            <ArrivalStateTag result={props.item.ArrivalState} />
          </div>
        </div>
        <div>
          <div>
            <p className="title-arrivage">REFERENCE</p>
          </div>
          <div>
            <p className="text-arrivage">{props.item.SupplyNumber}</p>
          </div>
        </div>
      </div>
    </>
  );
}
