import React, { useContext } from 'react';

const clientSelectedInfoContext = React.createContext();
const clientSelectedContext = React.createContext();
const clientSelectedToggleContext = React.createContext();
const clientSelectedAddInfoContext = React.createContext();

export function useClientSelected() {
    return useContext(clientSelectedContext);
}

export function useClientSelectedToggle() {
    return useContext(clientSelectedToggleContext);
}

export function useClientSelectedInfo() {
    return useContext(clientSelectedInfoContext);
}

export function useClientSelectedAddInfo() {
    return useContext(clientSelectedAddInfoContext);
}

export function ClientSelectedProvider({ children }) {
    const [clientSelectedInfo, setClientSelectedInfo] = React.useState({});
    const [clientIsSelected, setClientIsSelected] = React.useState(false);
    const changeClientIsSelected = () => setClientIsSelected(!clientIsSelected);
    const addClientSelectedInfo = (client) => setClientSelectedInfo(client);

    return (
        <clientSelectedInfoContext.Provider value={clientSelectedInfo}>
            <clientSelectedContext.Provider value={clientIsSelected}>
                <clientSelectedAddInfoContext.Provider value={addClientSelectedInfo}>
                    <clientSelectedToggleContext.Provider value={changeClientIsSelected}>
                        {children}
                    </clientSelectedToggleContext.Provider>
                </clientSelectedAddInfoContext.Provider>
            </clientSelectedContext.Provider>
        </clientSelectedInfoContext.Provider>
    );
}
