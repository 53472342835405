import React from 'react'

export const StatusTag = (props) => {
    function stockStatus (){
        switch(props.result.Status){
            case 0:
                return {color:'#27AE60', dispo: 'SUIVI'};
            case 1:
                return {color:'#EB5757', dispo: 'EN EPUISEMENT'};
            default:
                return {color:'#ffffff', dispo: "PAS D'INFO"};
        }
    }

    return(
        <p className="stock-tag" style={props.result.Status === 0 ? {display: 'none'} : {background: stockStatus()['color']}}>{stockStatus()['dispo']}</p>
    )
}