import React from 'react';

export const StockStatusTag = (props) => {
    function stockStatus (){
        switch(props.result.StockStatus){
            case 1:
                return {color:'#27AE60', dispo: 'EN STOCK'};
            case 2:
                return {color:'#F2C94C', dispo: 'SUR ARRIVAGE'};
            case 3:
                return {color:'#2D9CDB', dispo: 'SUR COMMANDE'};
            case 4:
                return {color:'#F2994A', dispo: 'RUP. MOMENTANEE'};
            case 5:
                return {color:'#EB5757', dispo: 'RUPTURE'};
            default:
                return {color:'#ffffff', dispo: "PAS D'INFO"};
        }
    }

    return(
        <p className="stock-tag" style={{background: stockStatus()['color']}}>{stockStatus()['dispo']}</p>
    )
}