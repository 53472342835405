import React from "react";


export function ClientCard(client, updateClient) {

    const handleClick = () => {
        updateClient(client, true)
    }

    return (
        <div className="results-container" key={client.Name + "1"}>
            {
                <button
                    key={client.clientName}
                    className="client-card"
                    onClick={() => { handleClick() }}
                >
                    <img
                        src={`./${client.clientName}.png`}
                        alt={client.clientName}
                        height="70"
                    />
                </button>
            }
        </div>
    )
}
