import React from 'react';

export const SaleStatusTag = (props) => {
    function SaleStatusTagDispo (){
        switch(props.result.SaleStatus){
            case 0:
                return {color:'#F2C94C', dispo: 'EN CREATION'};
            case 1:
                return {color:'#27AE60', dispo: 'EN VENTE'};
            case 2:
                return {color:'#F2994A', dispo: 'INTERDIT VENTE'};
            case 3:
                return {color:'#EB5757', dispo: 'ABANDONEE'};
            default:
                return {color:'#ffffff', dispo: "PAS D'INFO"};
        }
    }

    return(
        <p className="stock-tag" style={props.result.SaleStatus === 0 || props.result.SaleStatus === 1  ? {display: 'none'} : {background: SaleStatusTagDispo()['color']}}>{SaleStatusTagDispo()['dispo']}</p>
    )
}