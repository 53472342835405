import React, { useEffect, useState } from "react";
import LoginCard from "./components/authentication/loginCard";
import Search from "./components/Search";
import "./css/App.css";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { ClientCard } from "./components/clientBanner/clientCard";

function App() {
	const { loginWithRedirect, getAccessTokenSilently, isAuthenticated } =
		useAuth0();

	const [clients, setClients] = useState([]);
	const [client, setClient] = useState({});
	const [clientSelected, setClientSelected] = useState(false);

	useEffect(() => {
		if (isAuthenticated) {
			async function getAccessToken() {
				axios
					.get(
						"https://api-gateway.zagogroupe.com/api/internal/stock",
						{
							headers: {
								Authorization: `Bearer ${await getAccessTokenSilently()}`,
							},
						}
					)
					.then((res) => {
						setClients(res.data);
					});
			}
			getAccessToken();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);

	const updateClient = (client, clientSelected) => {
		setClient(client);
		setClientSelected(clientSelected);
	};

	return (
		<div className="App">
			{!isAuthenticated && LoginCard(loginWithRedirect, isAuthenticated)}

			{isAuthenticated && !clientSelected && (
				<div className="clients-screen">
					<div className="results-container">
						{clients.map((client) =>
							ClientCard(client, updateClient)
						)}
					</div>
				</div>
			)}
			{clientSelected && (
				<>
					<Search
						apiUrl={`https://api-gateway.zagogroupe.com/api/internal/client/${client.clientId[0]}/stock`}
						image={client.clientName}
						updateClient={updateClient}
					/>
				</>
			)}
		</div>
	);
}

export default App;
