import React, { useEffect } from "react";
import axios from "axios";
import "../css/search.css";
import "../css/index.css";
import HeaderSearch from "./header/HeaderSearch";
import Loader from "./Loader";
import RenderSearchResults from "./RenderSearchResults";
import FooterSticky from "./FooterSticky";
import { useAuth0 } from "@auth0/auth0-react";


export default function Search(props) {
  const [results, setResults] = React.useState({});
  const [token, setToken] = React.useState("");
  const [fileDate, setFileDate] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [scannerResult, setScannerResult] = React.useState("");

  console.log(props.updateClient);

  const {
		getAccessTokenSilently,
	} = useAuth0();

  useEffect (() => {
    getAccessToken();
  })

  async function getAccessToken() {
    try {
      const token = await getAccessTokenSilently();
      setToken(token);
    } catch (e) {
      console.log(e);
    }
  }

  const fetchSearchResults = (newQuery, scanner = 0) => {
    axios
      .get(props.apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const productsList = res.data.StockLineList.filter(
          product => product.ProductSku.includes(newQuery.toUpperCase()) 
        || product.Gencod.includes(newQuery) || product.ProductClientSku.includes(newQuery.toUpperCase()));
        setResults(productsList);
        let singularOrPlural = productsList.length > 1 ? 'produits' : 'produit';
        const strDate = res.data['Header']['Horodatage'].toString()
        setFileDate(strDate.substring(6,8) + '/' + strDate.substring(4,6) + '/' + strDate.substring(0,4) + " à " + strDate.substring(8,10) + ':' + strDate.substring(10,12) + ':' + strDate.substring(12,14))
        const resultNotFoundMsg = !productsList.length
        ? "Il n'y a pas de résultat pour les critères sélectionnés."
        : `Vous avez ${productsList.length} ${singularOrPlural} correspondant à votre recherche.`;
        setMessage(resultNotFoundMsg);
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          setLoading(false);
          setMessage(error.message);
        }
      });
  };

  const handleInputChange = (event) => {
    if(event.target.value.length > 0){
      setLoading(true);
      fetchSearchResults(event.target.value, 1);
    }else{
      setResults('');
      setMessage('');
    }
  };

  const onScanBarcode = (newScannerResult) => {
    fetchSearchResults(newScannerResult);
    setScannerResult(newScannerResult);
  };

  return (
    <div className="header-container">
      <HeaderSearch
        handleInputChange={handleInputChange}
        message={message}
        scannerResult={scannerResult}
        onScanBarcode={onScanBarcode}
        image={props.image}
        updateClient={props.updateClient}
      />
      <div className="container">
        {message ? (
          message && <p className="products-number">{message}</p>
        ) : (
          <Loader loading={loading} />
        )}
        {results && <RenderSearchResults results={results} />}
      </div>
      <FooterSticky date={fileDate}/>
    </div>
  );
}