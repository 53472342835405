import React from 'react';
import { StatusTag } from "./StatusTag";

export function StockCard(props) {
    return (
        <div className="stock-dispo-container">
            <div className="mini-card-stock">
                <div className="mini-card-stock-title">DISPONIBLE</div>
                <div className="mini-card-stock-number">{props.result.TotalQtyInStock}</div>
            </div>
            <div className="tag-container">
                <StatusTag result={props.result} />
            </div>
        </div>
    )
} 