import React from 'react';

import { SaleStatusTag } from "./SaleStatusTag";

export function AvailabilityDate(props) {
    let date = new Date(props.result.AvailabilityDate);
    return (
        <div className="availability-date-container">
            <div className="mini-card-stock">
                <div className="mini-card-stock-title">DISPO ENTREPOT</div>
                <div className="mini-card-date">{isNaN(date) ? '-/-/-' : date.toLocaleDateString()}</div>
            </div>
        <div className="tag-container">
            <SaleStatusTag result={props.result} />
        </div>
    </div>
    )
} 